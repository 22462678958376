<template>
  <div>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" md="3" lg="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          flat
          solo
          dense
          background-color="grey lighten-4"
          class="rounded-lg px-2 mt-1"
          :class="$vuetify.breakpoint.mdAndUp ? 'ml-3' : ''"
          clear-icon="mdi-close-circle"
          clearable
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-4" :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : ''">
      <v-card
        elevation="0"
        width="100%"
        :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
      >
        <v-data-table
          v-model="selected"
          color="primary"
          class="elevation-0 pa-0 overflow-auto"
          mobile-breakpoint="950"
          disable-sort
          item-key="task_id"
          :headers="headers"
          :items="taskTableData"
          :items-per-page="-1"
          :search="search"
          checkbox-color="primary"
          :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
          hide-default-footer
          hide-default-header
          :loading-text="$t('loading')"
        >
          <template v-slot:body="{ items }">
            <tbody v-if="$vuetify.breakpoint.mdAndUp">
              <draggable
                :list="items"
                group="items"
                draggable=".item:not(.non-drag)"
                @end="dragColumn(items.id, $event)"
              >
                <!--  -->
                <thead class="v-data-table-header non-drag">
                  <tr class="caption grey--text">
                    <th
                      id="taskdrag"
                      role="columnheader"
                      scope="col"
                      aria-label="Task Name"
                      class="text-start"
                    >
                      <span></span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-label="Task Name"
                      class="text-start"
                    >
                      <span>Task Name</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-label="Ticket"
                      class="text-center"
                      style="width: 220px; min-width: 220px"
                    >
                      <span>Ticket</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-label="Assigned To"
                      class="text-center section-width"
                    >
                      <span>Assigned To</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-label="Progress"
                      class="text-start"
                      style="width: 140px; min-width: 140px"
                    >
                      <span>Progress</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-label="Priority"
                      class="text-start"
                      style="width: 100px; min-width: 100px"
                    >
                      <span>Priority</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-label="Start Date"
                      class="text-start"
                      style="width: 150px; min-width: 150px"
                    >
                      <span>Start Date</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-label="End Date"
                      class="text-start"
                      style="width: 150px; min-width: 150px"
                    >
                      <span>End Date</span>
                    </th>
                    <th
                      id="taskedit"
                      role="columnheader"
                      scope="col"
                      aria-label="Edit"
                      class="text-start"
                      style="width: 80px; min-width: 80px"
                    >
                      <span>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tr v-for="item in items" :key="item.task_id" class="item">
                  <td id="taskdrag">
                    <div>
                      <v-btn plain>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                            fill="#757575"
                          />
                        </svg>
                      </v-btn>
                    </div>
                  </td>
                  <td id="taskname">
                    <div
                      class="my-auto text-subtitle-2 font-weight-bold ma-0"
                      v-if="!item['task_name_check']"
                      @click="editItem(item, 'task_name')"
                    >
                      <a
                        class="primary--text text-decoration-none multiline-ellipsis"
                        >{{ item.task_name }}</a
                      >
                    </div>
                    <v-text-field
                      v-else-if="item['task_name_check']"
                      class="mt-2"
                      outlined
                      dense
                      hide-details
                      autofocus
                      v-model="item['task_name']"
                      :key="'comments1'"
                      :return-value.sync="item['task_name']"
                      label="Edit"
                      single-line
                      @blur="editField(item, 'task_name')"
                      >{{
                        item["task_name"] !== "null" ? item["task_name"] : ""
                      }}</v-text-field
                    >
                  </td>
                  <td
                    id="taskticket"
                    @click="
                      !item['associated_ticket_ids_check'] &&
                        editItem(item, 'associated_ticket_ids')
                    "
                  >
                    <section
                      v-if="!item['associated_ticket_ids_check']"
                      class="avatars-group pa-3 stacked d-flex overflow-hidden"
                      @click="
                        checkPermission('createTask') &&
                          editItem(item, 'associated_ticket_ids')
                      "
                    >
                      <div
                        v-for="(avatar, index) in item['associated_ticket_ids']"
                        :key="`avatar-id-${avatar}-${index}`"
                        class="avatars-group__item"
                      >
                        <v-chip v-if="index < 1">
                          <span
                            class="grey--text text--darken-4 font-weight-regular body-2"
                          >
                            {{ associateTicketMap[avatar] }}
                          </span>
                        </v-chip>
                        <v-avatar
                          color="grey lighten-2"
                          size="35"
                          v-if="index === 1"
                        >
                          <span
                            class="grey--text text--darken-4 font-weight-regular body-2"
                          >
                            +{{
                              item["associated_ticket_ids"]
                                ? item["associated_ticket_ids"].length - 1
                                : 0
                            }}
                          </span>
                        </v-avatar>
                      </div>
                    </section>
                    <v-autocomplete
                      v-else-if="item['associated_ticket_ids' + '_check']"
                      v-model="item['associated_ticket_ids']"
                      :items="associateTicketList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      dense
                      autofocus
                      hide-details
                      required
                      multiple
                      solo
                      flat
                      item-text="ticket_number"
                      item-value="ticket_id"
                      @blur="editField(item, 'associated_ticket_ids')"
                    >
                      <template
                        v-slot:selection="{ attrs, item, parent, selected }"
                      >
                        <v-chip
                          size="small"
                          density="compact"
                          v-bind="attrs"
                          :input-value="selected"
                        >
                          <span class="pr-2">
                            {{ item.ticket_number }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            mdi-close-circle
                          </v-icon>
                        </v-chip>
                      </template></v-autocomplete
                    >
                  </td>
                  <td
                    id="taskassignee"
                    @click="
                      checkPermission('createTask') &&
                        !item['assignee_ids_check'] &&
                        editItem(item, 'assignee_ids')
                    "
                  >
                    <section
                      v-if="!item['assignee_ids_check']"
                      id="taskassignee"
                      class="avatars-group pa-3 stacked d-flex overflow-hidden"
                      :class="
                        item['assignee_ids'] && item['assignee_ids'].length <= 6
                          ? 'justify-center'
                          : ''
                      "
                      @click="
                        checkPermission('createTask') &&
                          editItem(item, 'assignee_ids')
                      "
                    >
                      <div
                        v-for="(avatar, index) in item['assignee_ids']"
                        :key="`avatar-id-${avatar}-${index}`"
                        class="avatars-group__item"
                      >
                        <v-avatar
                          color="blue lighten-4"
                          size="35"
                          v-if="index < 6"
                        >
                          <span
                            class="grey--text text--darken-4 font-weight-regular body-2"
                          >
                            {{
                              taskAssigneesMap[avatar]
                                ? GetInitials(taskAssigneesMap[avatar].name)
                                : ""
                            }}
                          </span>
                        </v-avatar>
                        <v-avatar
                          color="blue lighten-4"
                          size="35"
                          v-if="index === 6"
                        >
                          <span
                            class="grey--text text--darken-4 font-weight-regular body-2"
                          >
                            +{{
                              item["assignee_ids"]
                                ? item["assignee_ids"].length - 6
                                : 0
                            }}
                          </span>
                        </v-avatar>
                      </div>
                    </section>
                    <v-autocomplete
                      v-else-if="item['assignee_ids_check']"
                      v-model="item['assignee_ids']"
                      :items="taskAssigneesList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      dense
                      autofocus
                      hide-details
                      required
                      multiple
                      solo
                      flat
                      item-text="name"
                      item-value="user_id"
                      @blur="editField(item, 'assignee_ids')"
                    >
                      <template
                        v-slot:selection="{ attrs, item, parent, selected }"
                      >
                        <v-chip
                          size="small"
                          density="compact"
                          v-bind="attrs"
                          :input-value="selected"
                        >
                          <span class="pr-2">
                            {{ item.name }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            mdi-close-circle
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-select
                      class="mr-3 pa-0"
                      min-width="180px"
                      v-model="item.progress"
                      :items="taskProgressList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      solo
                      flat
                      dense
                      hide-details
                      @blur="editField(item, 'progress')"
                      @change="editField(item, 'progress')"
                    >
                    </v-select>
                  </td>
                  <td>
                    <v-select
                      v-if="checkPermission('createTask')"
                      class="mr-3 pa-0"
                      min-width="200px"
                      v-model="item.priority"
                      :items="taskPriorityList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      solo
                      flat
                      dense
                      hide-details
                      @blur="editField(item, 'priority')"
                      @change="editField(item, 'priority')"
                    ></v-select>
                    <span v-else>{{ item.priority }}</span>
                  </td>
                  <td v-if="checkPermission('createTask')">
                    <a
                      v-if="!item['start_date_check'] && item['start_date']"
                      class="mt-2 pl-0"
                      plain
                      block
                      :key="'start_date'"
                      single-line
                      @click="editItem(item, 'start_date')"
                      >{{
                        item["start_date"] ? formatDate(item["start_date"]) : ""
                      }}</a
                    >
                    <v-btn
                      v-else-if="
                        !item['start_date'] && !item['start_date_check']
                      "
                      class="mt-2 pl-0"
                      plain
                      block
                      single-line
                      @click="editItem(item, 'start_date')"
                      >{{
                        item["start_date"] ? formatDate(item["start_date"]) : ""
                      }}</v-btn
                    >
                    <v-menu
                      v-else-if="item['start_date_check']"
                      ref="dateMenu"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item['start_date']"
                          persistent-hint
                          outlined
                          dense
                          autofocus
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          hide-details
                          v-on="on"
                          @blur="editField(item, 'start_date')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item['start_date']"
                        no-title
                        :min="minDate"
                        @input="dateMenu = false"
                        @change="editField(item, 'start_date')"
                        @blur="editField(item, 'start_date')"
                      ></v-date-picker>
                    </v-menu>
                  </td>
                  <td v-else>
                    {{
                      item["start_date"] ? formatDate(item["start_date"]) : ""
                    }}
                  </td>
                  <td v-if="checkPermission('createTask')">
                    <a
                      v-if="!item['end_date_check'] && item['end_date']"
                      class="mt-2 pl-0"
                      plain
                      block
                      :key="'end_date'"
                      single-line
                      @click="editItem(item, 'end_date')"
                      >{{
                        item["end_date"] ? formatDate(item["end_date"]) : ""
                      }}</a
                    >
                    <v-btn
                      v-else-if="!item['end_date'] && !item['end_date_check']"
                      class="mt-2 pl-0"
                      plain
                      block
                      single-line
                      @click="editItem(item, 'end_date')"
                      >{{
                        item["end_date"] ? formatDate(item["end_date"]) : ""
                      }}</v-btn
                    >
                    <v-menu
                      v-else-if="item['end_date_check']"
                      ref="dateMenu"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                      nudge-left="100px"
                      @change="editField(item, 'end_date')"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item['end_date']"
                          persistent-hint
                          outlined
                          dense
                          autofocus
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          hide-details
                          v-on="on"
                          @blur="editField(item, 'end_date')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item['end_date']"
                        no-title
                        autofocus
                        :min="minEndDate"
                        @input="dateMenu = false"
                        position="fixed"
                        @change="editField(item, 'end_date')"
                        @blur="editField(item, 'end_date')"
                      ></v-date-picker>
                    </v-menu>
                  </td>
                  <td v-else>
                    {{ item["end_date"] ? formatDate(item["end_date"]) : "" }}
                  </td>
                  <td id="taskedit">
                    <v-icon
                      class="mr-2"
                      aria-label="Edit"
                      @click="editTask(item)"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </td>
                </tr>
              </draggable>
            </tbody>

            <tbody v-if="!$vuetify.breakpoint.mdAndUp">
              <tr v-for="item in items" :key="item.task_id">
                <v-card height="auto" elevation="0">
                  <v-row no-gutters justify="space-between">
                    <v-col cols="10" class="ml-1 mt-2 mb-1 py-0">
                      <span class="text-subtitle-2 font-weight-bold">
                        <a
                          class="primary--text text-decoration-none"
                          @click="editTask(item)"
                        >
                          {{ item.task_name }}</a
                        ></span
                      >
                    </v-col>
                    <v-icon
                      class="mr-2"
                      aria-label="Edit"
                      @click="editTask(item)"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </v-row>
                  <v-row
                    class="ml-1"
                    :class="
                      !item['associated_ticket_ids'] ||
                      item['associated_ticket_ids'].length === 0
                        ? 'my-2'
                        : ''
                    "
                    ><v-col
                      cols="6"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular my-auto"
                      >{{ $t("ticket") }}:</v-col
                    ><v-col class="d-flex justify-end pa-0 pr-4">
                      <section
                        class="avatars-group pa-3 stacked overflow-x-hidden py-0"
                      >
                        <div
                          v-for="(avatar, index) in item[
                            'associated_ticket_ids'
                          ]"
                          :key="`avatar-id-${avatar}-${index}`"
                          class="avatars-group__item"
                        >
                          <v-chip v-if="index < 1">
                            <span
                              class="grey--text text--darken-4 font-weight-regular body-2"
                            >
                              {{ associateTicketMap[avatar] }}
                            </span>
                          </v-chip>
                          <v-avatar
                            color="grey lighten-2"
                            size="35"
                            v-if="index === 1"
                          >
                            <span
                              class="grey--text text--darken-4 font-weight-regular body-2"
                            >
                              +{{
                                item["associated_ticket_ids"]
                                  ? item["associated_ticket_ids"].length - 1
                                  : 0
                              }}
                            </span>
                          </v-avatar>
                        </div>
                      </section>
                    </v-col></v-row
                  >
                  <v-row class="ml-1 my-2"
                    ><v-col
                      cols="4"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular my-auto"
                      >{{ $t("assignedTo") }}:</v-col
                    ><v-col
                      class="d-flex justify-end text-right pa-0 pr-2 text-body-2 text--darken-2 font-weight-medium"
                    >
                      <section
                        v-if="!item['assignee_ids_check']"
                        class="avatars-group pa-3 stacked overflow-x-hidden"
                      >
                        <div
                          v-for="(avatar, index) in item['assignee_ids']"
                          :key="`avatar-id-${avatar}-${index}`"
                          class="avatars-group__item"
                        >
                          <v-avatar
                            color="blue lighten-4"
                            size="35"
                            v-if="index < 6"
                          >
                            <span
                              class="grey--text text--darken-4 font-weight-regular body-2"
                            >
                              {{
                                taskAssigneesMap[avatar]
                                  ? GetInitials(taskAssigneesMap[avatar].name)
                                  : ""
                              }}
                            </span>
                          </v-avatar>
                          <v-avatar
                            color="blue lighten-4"
                            size="35"
                            v-if="index === 6"
                          >
                            <span
                              class="grey--text text--darken-4 font-weight-regular body-2"
                            >
                              +{{
                                item["assignee_ids"]
                                  ? item["assignee_ids"].length - 6
                                  : 0
                              }}
                            </span>
                          </v-avatar>
                        </div>
                      </section></v-col
                    ></v-row
                  >
                  <v-row class="ml-1 mt-1 mb-2">
                    <v-col
                      cols="6"
                      class="pa-0 my-auto text-body-2 text--darken-2 font-weight-regular"
                      >{{ $t("progress") }}:</v-col
                    >
                    <v-col
                      class="pa-0 text-body-2 text--darken-2 font-weight-medium"
                      ><v-select
                        class="mr-3 pa-0 fit ml-auto"
                        v-model="item.progress"
                        :items="taskProgressList"
                        :menu-props="{ bottom: true, offsetY: true }"
                        outlined
                        rounded
                        dense
                        flat
                        hide-details
                        :disabled="item.progress === '100%'"
                        @blur="editField(item, 'progress')"
                        @change="editField(item, 'progress')"
                      >
                      </v-select
                    ></v-col>
                  </v-row>

                  <v-row class="ml-1 my-2"
                    ><v-col
                      cols="7"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                      >{{ $t("priority") }}:</v-col
                    ><v-col
                      class="text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium"
                    >
                      {{ item["priority"] }}</v-col
                    ></v-row
                  >
                  <v-row class="ml-1 my-2"
                    ><v-col
                      cols="7"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                      >{{ $t("startDate") }}:</v-col
                    ><v-col
                      class="text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium"
                    >
                      {{
                        item["start_date"] ? formatDate(item["start_date"]) : ""
                      }}</v-col
                    ></v-row
                  >
                  <v-row class="ml-1 my-2"
                    ><v-col
                      cols="7"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                      >{{ $t("latestFinishDate") }}:</v-col
                    ><v-col
                      class="text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium"
                    >
                      {{
                        item["end_date"] ? formatDate(item["end_date"]) : ""
                      }}</v-col
                    ></v-row
                  >
                  <v-row class="ml-1 my-2"
                    ><v-col
                      cols="4"
                      class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                      >{{ $t("description") }}:</v-col
                    ><v-col
                      class="text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium"
                    >
                      {{ description(item) }}
                    </v-col></v-row
                  >
                </v-card>
                <v-divider></v-divider>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <div class="mr-3">
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp && checkPermission('createTask')"
            class="ml-3"
          >
            <v-btn id="add-task" elevation="0" @click="addTaskClicked"
              ><v-icon class="mr-2">mdi-plus-circle</v-icon>
              {{ $t("addTask") }}</v-btn
            >
          </v-col>
        </div>

        <div class="empty-task-state" v-if="this.taskTableData.length == 0">
          <v-card
            elevation="0"
            width="100%"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
          >
            <v-row>
              <span
                class="h6 font-weight-medium grey--text text--darken-4 mx-auto my-2"
              >
                {{ $t("noTasksAdded") }}
              </span>
            </v-row>
            <v-row>
              <span
                class="subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center"
                style="display: block">
              <template v-if="$vuetify.breakpoint.mdAndUp">
                Click the add task button to add task to this project
              </template>
              <template v-else>
                Click the add task button to add task <br> to this project
              </template>
              </span>
            </v-row>
          </v-card>
        </div>
      </v-card>
    </v-row>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown && checkPermission('createTask')"
      v-model="bottomNavbar"
      fixed
      horizontal
      height="70"
      class="px-2 py-4"
    >
      <v-row class="px-4 py-2">
        <v-btn
          color="primary"
          class="text-none rounded-lg font-weight-regular text-h6 white--text"
          block
          height="50"
          @click="addTaskClicked"
          >{{ $t("addTask") }}<v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-row>
    </v-bottom-navigation>
    <v-layout>
      <div
        v-if="$vuetify.breakpoint.mdAndUp && addTaskDialog"
        id="addTaskPanel"
      >
        <AddTask
          @taskAdded="taskAdded"
          @hideTaskDialog="hideTaskDialog"
          @taskDeleted="removeDeletedTask"
          @closeTaskDialog="closeTaskDialog"
          :edit-task-details="editTaskData"
          :project-details="projectDetail"
        />
      </div>
      <v-dialog
        v-if="!$vuetify.breakpoint.mdAndUp && addTaskDialog"
        v-model="addTaskDialog"
        fullscreen
        width="auto"
        height="auto"
      >
        <AddTask
          @taskAdded="taskAdded"
          @hideTaskDialog="hideTaskDialog"
          @taskDeleted="removeDeletedTask"
          @closeTaskDialog="closeTaskDialog"
          :project-details="projectDetail"
          :edit-task-details="editTaskData"
        />
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import AddTask from "./AddTask.vue";
import moment from "moment";
import UserDataMixin from "@/mixins/UserData";
import api from "../../../store/api";
import { bus } from "@/main";
import Draggable from "vuedraggable";
export default {
  components: { Draggable, AddTask: () => import("./AddTask.vue") },
  mixins: [UserDataMixin],
  data() {
    return {
      centers: [],
      search: "",
      taskTableData: [],
      addTaskDialog: false,
      taskProgressList: ["0%", "25%", "50%", "75%", "100%"],
      taskPriorityList: ["High", "Medium", "Low"],
      selected: [],
      dateMenu: false,
      bottomNavbar: true,
      editTaskData: {},
      editItemTask: {},
      editItemkey: "",
      headers: [
        {
          text: "Task Name",
          value: "task_name",
        },
        {
          text: "Ticket",
          value: "associated_ticket_id",
          align: "center",
          width: "220",
        },
        {
          text: "Assigned To",
          value: "assignee_ids",
          class: "section-width",
          align: "center",
        },
        { text: "Progress", value: "progress", width: 140, align: "start" },
        { text: "Priority", value: "priority", width: 100, align: "start" },
        {
          text: "Start Date",
          value: "start_date",
          width: "150",
          align: "start",
        },
        { text: "End Date", value: "end_date", width: "150", align: "start" },
        {
          text: "Edit",
          value: "edit",
          align: "start",
          width: "80",
        },
      ],
      tickets: [],
    };
  },
  computed: {
    associateTicketList() {
      const ticketData = this.tickets;
      let ticketList = [];

      if (ticketData) {
        ticketList = Object.entries(ticketData).map(([key, value]) => {
          let ticketNumber = "";
          if (value.revision)
            ticketNumber = `${value.center_ticket_id}-${value.revision}`;
          else ticketNumber = `${value.center_ticket_id}`;
          return {
            ticket_number: ticketNumber,
            ticket_id: value.ticket_id,
            center_ticket_id: value.center_ticket_id,
            project_id: value.project_id,
          };
        });
        ticketList = ticketList.filter((ticket) => {
          return (
            !ticket.center_ticket_id.startsWith("S") &&
            ticket.project_id &&
            this.projectInfo &&
            this.projectInfo.project_id &&
            ticket.project_id == this.projectInfo.project_id
          );
        });
      }
      return ticketList;
    },
    associateTicketMap() {
      const ticketData = this.tickets;
      let ticketList = [];
      let ticketMap = {};
      if (ticketData && ticketData) {
        ticketList = Object.entries(ticketData).map(([key, value]) => {
          let ticketNumber = "";
          if (value.revision)
            ticketNumber = `${value.center_ticket_id}-${value.revision}`;
          else ticketNumber = `${value.center_ticket_id}`;
          ticketMap[value.ticket_id] = ticketNumber;
          return {
            ticket_number: ticketNumber,
            ticket_id: value.ticket_id,
            center_ticket_id: value.center_ticket_id,
            project_id: value.project_id,
          };
        });
      }
      return ticketMap;
    },
    projectInfo() {
      const project = this.$store.state.Project.project.find((project) => {
        return project.project_id === this.projectDetail.project_id;
      });
      return project;
    },
    minDate() {
      return moment(
        new Date(this.formatDate(this.projectInfo.start_date)),
      ).format("YYYY-MM-DD");
    },
    minEndDate() {
      return this.editItemTask.start_date
        ? new Date(this.editItemTask.start_date).toISOString().slice(0, 10)
        : new Date(this.projectInfo.start_date).toISOString().slice(0, 10);
    },
    taskAssigneesList() {
      const companyUsers = this.$store.state.User.companyUsers;
      let assignees = [];
      if (companyUsers.length) {
        assignees = companyUsers.filter((user) => {
          return user.status === "active";
        });
      }
      return assignees;
    },
    taskAssigneesMap() {
      const companyUsers = this.$store.state.User.companyUserMap;
      return companyUsers;
    },
  },
  watch: {
    dateMenu() {
      this.editField(this.editItemTask, this.editItemkey);
    },
  },
  props: {
    projectDetail: {
      type: Object,
      default: () => {},
    },
    projectId: {
      type: String,
      default: "",
    },
  },
  async created() {
    this.moment = moment;
    this.getTicketData();
    this.getTaskData();
    if (this.$store.state.Project.project.length == 0) this.getProjectData();
  },
  methods: {
    async dragColumn(id, ev) {
      if (ev.oldIndex === ev.newIndex) return;
      let item = this.taskTableData[ev.oldIndex - 1];
      item.task_index = ev.newIndex == 0 ? 1 : ev.newIndex;
      this.taskTableData = this.taskTableData.filter((element) => {
        return element.task_id !== item.task_id;
      });
      const tempData = JSON.parse(JSON.stringify(this.taskTableData));
      tempData.splice(ev.newIndex - 1, 0, item);
      let payload = {
        drag_index: ev.oldIndex,
        drop_index: ev.newIndex == 0 ? 1 : ev.newIndex,
        task_id: item.task_id,
        project_id: item.project_id,
      };
      let temp = tempData;
      this.taskTableData = [];
      this.$nextTick(() => {
        this.taskTableData = JSON.parse(JSON.stringify(temp));
      });
      const projectDataResponse = await this.$store.dispatch(
        "reodertaskrow",
        payload,
      );
    },
    async getTicketData() {
      const apiFilter = {};
      apiFilter.project_id = this.projectDetail.project_id;
      let response = await api.ticket.getTickets(apiFilter);
      for (let ticket of response.tickets) {
        ticket.fullAddress =
          ticket.street_address +
          " " +
          ticket.place +
          " " +
          ticket.county +
          " " +
          ticket.state;
        ticket.center_name = this.getCenterName(ticket.center_id);
      }
      this.tickets = response.tickets.filter((ticket) => {
        return !ticket.center_ticket_id.startsWith("S");
      });
    },
    getCenterName(centerId) {
      let center = this.centers.find((center) => center.center_id === centerId);
      return center ? center.center_name : "";
    },
    description(item) {
      return item["task_desc"]?.length > 25
        ? item["task_desc"].slice(0, 25) + "…"
        : item["task_desc"];
    },
    GetInitials(name) {
      const parts = name.split(" ");
      let initials = "";
      for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      return initials;
    },
    async getProjectData() {
      bus.$emit("updateProjectDetails");
      const projectDataResponse =
        await this.$store.dispatch("getCompanyProject");
      //await this.usersCompany;
      if (projectDataResponse.status !== "error") {
        if (projectDataResponse && projectDataResponse.length === 0)
          this.isProjectEmpty = true;
        else this.isProjectEmpty = false;
        this.projectData = false;
        projectDataResponse.forEach((item) => {
          item.progress = item.no_of_task
            ? parseInt((item.no_of_completed_task / item.no_of_task) * 100)
            : 0;
          item.tasks = item.no_of_task ? item.no_of_task : 0;
          item.attachments = 3;
          if (item.status === null) item.status = "Planning";
        });
        this.$store.commit("setProjectData", projectDataResponse);
      }
    },
    async getTaskData() {
      const apiParams = {
        project_id: this.projectId,
      };
      const projectDataResponse = await this.$store.dispatch(
        "getProjectTaskById",
        apiParams,
      );
      if (projectDataResponse.status !== "error") {
        this.taskTableData = [];
        projectDataResponse.forEach((element) => {
          element.id = element.task_id;
          if (!element.progress) element.progress = "0%";
          else element.progress = element.progress + "%";
          if (!element.start_date) element.start_date = null;
          else element.start_date = element.start_date.split("T")[0];
          if (!element.end_date) element.end_date = null;
          else element.end_date = element.end_date.split("T")[0];
          if (element.is_complete) this.selected.push(element);
        });
        this.taskTableData = projectDataResponse;
      }
    },
    async addTaskClicked() {
      let taskNumber = this.taskTableData.length + 1;
      let emptyRow = {
        task_name: "New Task " + taskNumber,
        priority: "Low",
        project_id: this.projectDetail.project_id,
      };
      const projectDataResponse = await this.$store.dispatch(
        "createProjectTask",
        emptyRow,
      );
      if (projectDataResponse.status !== "error") {
        emptyRow.task_id = projectDataResponse[0].task_id;
        emptyRow.start_date = null;
        emptyRow.end_date = null;
        emptyRow.created_at = new Date().toISOString().slice(0, 10);
        emptyRow.progress = "0%";
        emptyRow.task_index = this.taskTableData.length + 1;
        this.taskTableData.push(emptyRow);
        this.getProjectData();
      }
    },
    taskAdded() {
      this.addTaskDialog = false;
      this.$emit("taskAdded");
    },
    //Hides dialog and updates data
    async hideTaskDialog(task) {
      const taskData = [...this.taskTableData];
      for (let i = 0; i < taskData.length; i++) {
        if (task.task_id === taskData[i].task_id) {
          if (!task.end_date) task.end_date = null;
          if (task.associated_ticket_ids)
            taskData[i].associated_ticket_ids = task.associated_ticket_ids;
          if (task.assignee_ids) taskData[i].assignee_ids = task.assignee_ids;
          if (task.todo_items) taskData[i].todo_items = task.todo_items;
          if (task.progress) taskData[i].progress = task.progress;
          if (task.todo_items) taskData[i].todo_items = task.todo_items;
          if (task.end_date) {
            taskData[i].end_date = task.end_date;
          }
          if (task.start_date) {
            taskData[i].start_date = null;
            taskData[i].start_date = task.start_date;
          }

          if (!taskData[i].progress) taskData[i].progress = "0%";
          else taskData[i].progress = taskData[i].progress + "%";
          this.$nextTick(() => {
            this.taskTableData = [];
            this.taskTableData = taskData;
          });
          break;
        }
      }
      await this.getProjectData();
      this.addTaskDialog = false;
      await this.getTaskData();
    },
    removeDeletedTask(task) {
      const deletedId = task[0].task_id;
      const tasks = this.taskTableData.filter((item) => {
        return deletedId !== item.task_id;
      });
      this.taskTableData = tasks;
      this.getProjectData();
      this.addTaskDialog = false;
    },
    //Hides dialog with no data update
    closeTaskDialog() {
      this.addTaskDialog = false;
    },
    editTask(item) {
      window.scrollTo(0, 0);
      this.addTaskDialog = true;
      this.editTaskData = item;
    },
    editItem(item, keyName) {
      this.editItemTask = item;
      this.editItemkey = keyName;
      let editedIndex = this.taskTableData.indexOf(item);
      let obj = Object.assign({}, item);
      obj[keyName + "_check"] = true;
      let key = item[keyName];
      Object.assign(this.taskTableData[editedIndex], obj);
      const tableData = [...this.taskTableData];
      this.taskTableData = [];
      this.taskTableData = tableData;
    },
    async editField(item, keyName) {
      if (keyName === "start_date" || keyName === "end_date") {
        setTimeout(async () => {
          if (this.dateMenu) return;
          let editedIndex = this.taskTableData.indexOf(item);
          let obj = Object.assign({}, item);
          obj[keyName + "_check"] = false;
          let key = item[keyName];
          Object.assign(this.taskTableData[editedIndex], obj);
          const tableData = [...this.taskTableData];
          this.taskTableData = [];
          this.taskTableData = tableData;
          let updateObject = { task_id: item.task_id };
          updateObject[keyName] = item[keyName];
          if (keyName === "start_date") updateObject.end_date = item.end_date;
          else updateObject.start_date = item.start_date;
          const updatetaskResp = await this.$store.dispatch(
            "updateProjectTask",
            updateObject,
          );
          if (updatetaskResp.status === "error") {
            console.log("error in task update");
          } else {
            this.getProjectData();
          }
        }, 500);
      } else {
        let editedIndex = this.taskTableData.indexOf(item);
        let obj = Object.assign({}, item);
        obj[keyName + "_check"] = false;
        let key = item[keyName];
        Object.assign(this.taskTableData[editedIndex], obj);
        const tableData = [...this.taskTableData];
        this.taskTableData = [];
        this.taskTableData = tableData;
        let updateObject = { task_id: item.task_id };
        updateObject[keyName] = item[keyName];
        if (keyName === "progress") {
          updateObject[keyName] = item[keyName].slice(0, -1);
          if (item.progress === "100%") {
            updateObject.is_complete = true;
            updateObject.step_id = !this.projectInfo.options
              ? 2
              : this.projectInfo.options.steps.length - 1;
            item.is_complete = true;
            if (item.is_complete) this.selected.push(item);
          }
        }
        if (keyName === "assignee_ids" || keyName === "associated_ticket_ids")
          updateObject[keyName] = updateObject[keyName].join(",");
        const updatetaskResp = await this.$store.dispatch(
          "updateProjectTask",
          updateObject,
        );
        if (updatetaskResp.status === "error") {
          console.log("error in task update");
        } else {
          this.getProjectData();
        }
      }
    },
    formatDate(date) {
      if (!date) return null;
      if (typeof date == "boolean") return null;
      const [year, month, day] = date.split("T")[0].split("-");
      return month ? `${month}/${day}/${year}` : date;
    },
  },
};
</script>
<style lang="scss">
#description-btn .v-btn__content {
  width: 200px;
}
td {
  max-width: 200px;
}
th {
  min-width: 100px;
  max-width: 200px;
}
table {
  display: table;
  width: 100%;
}
thead {
  width: 100%;
}
.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}
.v-select.fit {
  width: min-content;
}
.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}
.avatars-group.stacked {
  display: flex;
  flex-direction: row;
  direction: ltr;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.avatars-group.stacked > * {
  margin-right: -15px;
}
.avatars-group.stacked > *:last-of-type {
  padding-right: 16px;
}
.avatars-group__item {
  cursor: default;
  transition: all 0.1s ease-out;
}
.avatars-group__item.more {
  align-items: center;
  display: flex;
}
.avatars-group__item.more:hover {
  transform: none;
}
.avatars-group__item:hover {
  transform: translateY(-4px);
  z-index: 1;
}
.avatars-group .v-avatar {
  box-shadow: 0px 0px 0px 2px #fff inset;
}
.avatars-group .v-avatar img {
  padding: 2px;
}
.avatars-group .v-avatar span {
  align-items: center;
  display: flex;
  font-size: 110%;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  letter-spacing: 0.1rem;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.56);
  width: inherit;
}
.v-avatar.bordered {
  box-shadow: 0px 0px 0px 2px #fff inset;
}
.v-avatar.bordered img {
  padding: 2px;
}
.v-avatar.bordered.small {
  box-shadow: 0px 0px 0px 1px #fff inset;
}
.v-avatar.bordered.small img {
  padding: 1px;
}
.presence {
  box-shadow: 0px 0px 0px 2px #fff inset;
  border-radius: 50%;
  bottom: 0px;
  display: block;
  height: 14px;
  position: absolute;
  width: 14px;
}
.v-avatar.bordered.small .presence {
  box-shadow: 0px 0px 0px 1px #fff inset;
  display: block;
  height: 8px;
  position: absolute;
  width: 8px;
}
.section-width {
  min-width: 5%;
  max-width: 8%;
}
</style>
<style scoped>
#taskassignee {
  width: 13%;
  min-width: 170px;
}
#taskticket {
  width: 12%;
}
#taskname {
  width: 15%;
}
#taskdrag {
  min-width: 40px;
  width: 1%;
}
#taskedit {
  min-width: 40px;
  width: 1%;
}
.v-data-table__empty-wrapper {
  display: none;
}
#add-task {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #0073d1;
  text-transform: capitalize;
}
.empty-task-state {
  display: flex;
  justify-content: center;
  height: auto;
  align-items: center;
}
#addTaskPanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 560px;
  height: calc(100vh);
  transform-origin: center center;
  max-width: 560px;
  margin-right: 0px;
  margin-top: -60px;
  margin-left: 0px;
  margin-bottom: -20px;
  z-index: 100;
  background-color: #fff;
  border-left: 1px solid #e0e0e0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
}
</style>
