var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: _vm.$vuetify.breakpoint.mdAndUp ? "4" : "12",
                md: "3",
                lg: "4",
              },
            },
            [
              _c("v-text-field", {
                staticClass: "rounded-lg px-2 mt-1",
                class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-3" : "",
                attrs: {
                  "prepend-inner-icon": "mdi-magnify",
                  label: _vm.$t("search"),
                  "single-line": "",
                  "hide-details": "",
                  flat: "",
                  solo: "",
                  dense: "",
                  "background-color": "grey lighten-4",
                  "clear-icon": "mdi-close-circle",
                  clearable: "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "mt-4",
          class: _vm.$vuetify.breakpoint.smAndDown ? "pa-4" : "",
        },
        [
          _c(
            "v-card",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "mb-12" : null,
              attrs: { elevation: "0", width: "100%" },
            },
            [
              _c("v-data-table", {
                staticClass: "elevation-0 pa-0 overflow-auto",
                class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-2" : null,
                attrs: {
                  color: "primary",
                  "mobile-breakpoint": "950",
                  "disable-sort": "",
                  "item-key": "task_id",
                  headers: _vm.headers,
                  items: _vm.taskTableData,
                  "items-per-page": -1,
                  search: _vm.search,
                  "checkbox-color": "primary",
                  "hide-default-footer": "",
                  "hide-default-header": "",
                  "loading-text": _vm.$t("loading"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "body",
                    fn: function (ref) {
                      var items = ref.items
                      return [
                        _vm.$vuetify.breakpoint.mdAndUp
                          ? _c(
                              "tbody",
                              [
                                _c(
                                  "draggable",
                                  {
                                    attrs: {
                                      list: items,
                                      group: "items",
                                      draggable: ".item:not(.non-drag)",
                                    },
                                    on: {
                                      end: function ($event) {
                                        return _vm.dragColumn(items.id, $event)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "thead",
                                      {
                                        staticClass:
                                          "v-data-table-header non-drag",
                                      },
                                      [
                                        _c(
                                          "tr",
                                          { staticClass: "caption grey--text" },
                                          [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-start",
                                                attrs: {
                                                  id: "taskdrag",
                                                  role: "columnheader",
                                                  scope: "col",
                                                  "aria-label": "Task Name",
                                                },
                                              },
                                              [_c("span")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-start",
                                                attrs: {
                                                  role: "columnheader",
                                                  scope: "col",
                                                  "aria-label": "Task Name",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Task Name"),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                staticStyle: {
                                                  width: "220px",
                                                  "min-width": "220px",
                                                },
                                                attrs: {
                                                  role: "columnheader",
                                                  scope: "col",
                                                  "aria-label": "Ticket",
                                                },
                                              },
                                              [_c("span", [_vm._v("Ticket")])]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "text-center section-width",
                                                attrs: {
                                                  role: "columnheader",
                                                  scope: "col",
                                                  "aria-label": "Assigned To",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Assigned To"),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-start",
                                                staticStyle: {
                                                  width: "140px",
                                                  "min-width": "140px",
                                                },
                                                attrs: {
                                                  role: "columnheader",
                                                  scope: "col",
                                                  "aria-label": "Progress",
                                                },
                                              },
                                              [_c("span", [_vm._v("Progress")])]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-start",
                                                staticStyle: {
                                                  width: "100px",
                                                  "min-width": "100px",
                                                },
                                                attrs: {
                                                  role: "columnheader",
                                                  scope: "col",
                                                  "aria-label": "Priority",
                                                },
                                              },
                                              [_c("span", [_vm._v("Priority")])]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-start",
                                                staticStyle: {
                                                  width: "150px",
                                                  "min-width": "150px",
                                                },
                                                attrs: {
                                                  role: "columnheader",
                                                  scope: "col",
                                                  "aria-label": "Start Date",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Start Date"),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-start",
                                                staticStyle: {
                                                  width: "150px",
                                                  "min-width": "150px",
                                                },
                                                attrs: {
                                                  role: "columnheader",
                                                  scope: "col",
                                                  "aria-label": "End Date",
                                                },
                                              },
                                              [_c("span", [_vm._v("End Date")])]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-start",
                                                staticStyle: {
                                                  width: "80px",
                                                  "min-width": "80px",
                                                },
                                                attrs: {
                                                  id: "taskedit",
                                                  role: "columnheader",
                                                  scope: "col",
                                                  "aria-label": "Edit",
                                                },
                                              },
                                              [_c("span", [_vm._v("Edit")])]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._l(items, function (item) {
                                      return _c(
                                        "tr",
                                        {
                                          key: item.task_id,
                                          staticClass: "item",
                                        },
                                        [
                                          _c(
                                            "td",
                                            { attrs: { id: "taskdrag" } },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    { attrs: { plain: "" } },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          attrs: {
                                                            width: "24",
                                                            height: "24",
                                                            viewBox:
                                                              "0 0 24 24",
                                                            fill: "none",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z",
                                                              fill: "#757575",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { attrs: { id: "taskname" } },
                                            [
                                              !item["task_name_check"]
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "my-auto text-subtitle-2 font-weight-bold ma-0",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editItem(
                                                            item,
                                                            "task_name"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "primary--text text-decoration-none multiline-ellipsis",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.task_name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : item["task_name_check"]
                                                ? _c(
                                                    "v-text-field",
                                                    {
                                                      key: "comments1",
                                                      staticClass: "mt-2",
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        "hide-details": "",
                                                        autofocus: "",
                                                        "return-value":
                                                          item["task_name"],
                                                        label: "Edit",
                                                        "single-line": "",
                                                      },
                                                      on: {
                                                        "update:returnValue":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              item,
                                                              "task_name",
                                                              $event
                                                            )
                                                          },
                                                        "update:return-value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              item,
                                                              "task_name",
                                                              $event
                                                            )
                                                          },
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.editField(
                                                            item,
                                                            "task_name"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item["task_name"],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "task_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item['task_name']",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item["task_name"] !==
                                                            "null"
                                                            ? item["task_name"]
                                                            : ""
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            {
                                              attrs: { id: "taskticket" },
                                              on: {
                                                click: function ($event) {
                                                  !item[
                                                    "associated_ticket_ids_check"
                                                  ] &&
                                                    _vm.editItem(
                                                      item,
                                                      "associated_ticket_ids"
                                                    )
                                                },
                                              },
                                            },
                                            [
                                              !item[
                                                "associated_ticket_ids_check"
                                              ]
                                                ? _c(
                                                    "section",
                                                    {
                                                      staticClass:
                                                        "avatars-group pa-3 stacked d-flex overflow-hidden",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.checkPermission(
                                                            "createTask"
                                                          ) &&
                                                            _vm.editItem(
                                                              item,
                                                              "associated_ticket_ids"
                                                            )
                                                        },
                                                      },
                                                    },
                                                    _vm._l(
                                                      item[
                                                        "associated_ticket_ids"
                                                      ],
                                                      function (avatar, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key:
                                                              "avatar-id-" +
                                                              avatar +
                                                              "-" +
                                                              index,
                                                            staticClass:
                                                              "avatars-group__item",
                                                          },
                                                          [
                                                            index < 1
                                                              ? _c("v-chip", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "grey--text text--darken-4 font-weight-regular body-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .associateTicketMap[
                                                                              avatar
                                                                            ]
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            index === 1
                                                              ? _c(
                                                                  "v-avatar",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "grey lighten-2",
                                                                      size: "35",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text text--darken-4 font-weight-regular body-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " +" +
                                                                            _vm._s(
                                                                              item[
                                                                                "associated_ticket_ids"
                                                                              ]
                                                                                ? item[
                                                                                    "associated_ticket_ids"
                                                                                  ]
                                                                                    .length -
                                                                                    1
                                                                                : 0
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : item[
                                                    "associated_ticket_ids" +
                                                      "_check"
                                                  ]
                                                ? _c("v-autocomplete", {
                                                    attrs: {
                                                      items:
                                                        _vm.associateTicketList,
                                                      "menu-props": {
                                                        bottom: true,
                                                        offsetY: true,
                                                      },
                                                      dense: "",
                                                      autofocus: "",
                                                      "hide-details": "",
                                                      required: "",
                                                      multiple: "",
                                                      solo: "",
                                                      flat: "",
                                                      "item-text":
                                                        "ticket_number",
                                                      "item-value": "ticket_id",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        return _vm.editField(
                                                          item,
                                                          "associated_ticket_ids"
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "selection",
                                                          fn: function (ref) {
                                                            var attrs =
                                                              ref.attrs
                                                            var item = ref.item
                                                            var parent =
                                                              ref.parent
                                                            var selected =
                                                              ref.selected
                                                            return [
                                                              _c(
                                                                "v-chip",
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      size: "small",
                                                                      density:
                                                                        "compact",
                                                                      "input-value":
                                                                        selected,
                                                                    },
                                                                  },
                                                                  "v-chip",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "pr-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.ticket_number
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return parent.selectItem(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-close-circle "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        item[
                                                          "associated_ticket_ids"
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "associated_ticket_ids",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item['associated_ticket_ids']",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            {
                                              attrs: { id: "taskassignee" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.checkPermission(
                                                    "createTask"
                                                  ) &&
                                                    !item[
                                                      "assignee_ids_check"
                                                    ] &&
                                                    _vm.editItem(
                                                      item,
                                                      "assignee_ids"
                                                    )
                                                },
                                              },
                                            },
                                            [
                                              !item["assignee_ids_check"]
                                                ? _c(
                                                    "section",
                                                    {
                                                      staticClass:
                                                        "avatars-group pa-3 stacked d-flex overflow-hidden",
                                                      class:
                                                        item["assignee_ids"] &&
                                                        item["assignee_ids"]
                                                          .length <= 6
                                                          ? "justify-center"
                                                          : "",
                                                      attrs: {
                                                        id: "taskassignee",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.checkPermission(
                                                            "createTask"
                                                          ) &&
                                                            _vm.editItem(
                                                              item,
                                                              "assignee_ids"
                                                            )
                                                        },
                                                      },
                                                    },
                                                    _vm._l(
                                                      item["assignee_ids"],
                                                      function (avatar, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key:
                                                              "avatar-id-" +
                                                              avatar +
                                                              "-" +
                                                              index,
                                                            staticClass:
                                                              "avatars-group__item",
                                                          },
                                                          [
                                                            index < 6
                                                              ? _c(
                                                                  "v-avatar",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "blue lighten-4",
                                                                      size: "35",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text text--darken-4 font-weight-regular body-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .taskAssigneesMap[
                                                                                avatar
                                                                              ]
                                                                                ? _vm.GetInitials(
                                                                                    _vm
                                                                                      .taskAssigneesMap[
                                                                                      avatar
                                                                                    ]
                                                                                      .name
                                                                                  )
                                                                                : ""
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            index === 6
                                                              ? _c(
                                                                  "v-avatar",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "blue lighten-4",
                                                                      size: "35",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text text--darken-4 font-weight-regular body-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " +" +
                                                                            _vm._s(
                                                                              item[
                                                                                "assignee_ids"
                                                                              ]
                                                                                ? item[
                                                                                    "assignee_ids"
                                                                                  ]
                                                                                    .length -
                                                                                    6
                                                                                : 0
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : item["assignee_ids_check"]
                                                ? _c("v-autocomplete", {
                                                    attrs: {
                                                      items:
                                                        _vm.taskAssigneesList,
                                                      "menu-props": {
                                                        bottom: true,
                                                        offsetY: true,
                                                      },
                                                      dense: "",
                                                      autofocus: "",
                                                      "hide-details": "",
                                                      required: "",
                                                      multiple: "",
                                                      solo: "",
                                                      flat: "",
                                                      "item-text": "name",
                                                      "item-value": "user_id",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        return _vm.editField(
                                                          item,
                                                          "assignee_ids"
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "selection",
                                                          fn: function (ref) {
                                                            var attrs =
                                                              ref.attrs
                                                            var item = ref.item
                                                            var parent =
                                                              ref.parent
                                                            var selected =
                                                              ref.selected
                                                            return [
                                                              _c(
                                                                "v-chip",
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      size: "small",
                                                                      density:
                                                                        "compact",
                                                                      "input-value":
                                                                        selected,
                                                                    },
                                                                  },
                                                                  "v-chip",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "pr-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return parent.selectItem(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-close-circle "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        item["assignee_ids"],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "assignee_ids",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item['assignee_ids']",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c("v-select", {
                                                staticClass: "mr-3 pa-0",
                                                attrs: {
                                                  "min-width": "180px",
                                                  items: _vm.taskProgressList,
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true,
                                                  },
                                                  solo: "",
                                                  flat: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.editField(
                                                      item,
                                                      "progress"
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    return _vm.editField(
                                                      item,
                                                      "progress"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.progress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "progress",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.progress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.checkPermission("createTask")
                                                ? _c("v-select", {
                                                    staticClass: "mr-3 pa-0",
                                                    attrs: {
                                                      "min-width": "200px",
                                                      items:
                                                        _vm.taskPriorityList,
                                                      "menu-props": {
                                                        bottom: true,
                                                        offsetY: true,
                                                      },
                                                      solo: "",
                                                      flat: "",
                                                      dense: "",
                                                      "hide-details": "",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        return _vm.editField(
                                                          item,
                                                          "priority"
                                                        )
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.editField(
                                                          item,
                                                          "priority"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: item.priority,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "priority",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.priority",
                                                    },
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.priority)
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          ),
                                          _vm.checkPermission("createTask")
                                            ? _c(
                                                "td",
                                                [
                                                  !item["start_date_check"] &&
                                                  item["start_date"]
                                                    ? _c(
                                                        "a",
                                                        {
                                                          key: "start_date",
                                                          staticClass:
                                                            "mt-2 pl-0",
                                                          attrs: {
                                                            plain: "",
                                                            block: "",
                                                            "single-line": "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editItem(
                                                                item,
                                                                "start_date"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item["start_date"]
                                                                ? _vm.formatDate(
                                                                    item[
                                                                      "start_date"
                                                                    ]
                                                                  )
                                                                : ""
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : !item["start_date"] &&
                                                      !item["start_date_check"]
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mt-2 pl-0",
                                                          attrs: {
                                                            plain: "",
                                                            block: "",
                                                            "single-line": "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editItem(
                                                                item,
                                                                "start_date"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item["start_date"]
                                                                ? _vm.formatDate(
                                                                    item[
                                                                      "start_date"
                                                                    ]
                                                                  )
                                                                : ""
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : item["start_date_check"]
                                                    ? _c(
                                                        "v-menu",
                                                        {
                                                          ref: "dateMenu",
                                                          refInFor: true,
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "max-width":
                                                              "290px",
                                                            "min-width": "auto",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "persistent-hint":
                                                                                  "",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                autofocus:
                                                                                  "",
                                                                                "prepend-inner-icon":
                                                                                  "mdi-calendar",
                                                                                "hide-details":
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              blur: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.editField(
                                                                                  item,
                                                                                  "start_date"
                                                                                )
                                                                              },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  item[
                                                                                    "start_date"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item,
                                                                                      "start_date",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "item['start_date']",
                                                                              },
                                                                          },
                                                                          "v-text-field",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value: _vm.dateMenu,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.dateMenu = $$v
                                                            },
                                                            expression:
                                                              "dateMenu",
                                                          },
                                                        },
                                                        [
                                                          _c("v-date-picker", {
                                                            attrs: {
                                                              "no-title": "",
                                                              min: _vm.minDate,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                _vm.dateMenu = false
                                                              },
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.editField(
                                                                  item,
                                                                  "start_date"
                                                                )
                                                              },
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.editField(
                                                                  item,
                                                                  "start_date"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item[
                                                                  "start_date"
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "start_date",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item['start_date']",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item["start_date"]
                                                        ? _vm.formatDate(
                                                            item["start_date"]
                                                          )
                                                        : ""
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                          _vm.checkPermission("createTask")
                                            ? _c(
                                                "td",
                                                [
                                                  !item["end_date_check"] &&
                                                  item["end_date"]
                                                    ? _c(
                                                        "a",
                                                        {
                                                          key: "end_date",
                                                          staticClass:
                                                            "mt-2 pl-0",
                                                          attrs: {
                                                            plain: "",
                                                            block: "",
                                                            "single-line": "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editItem(
                                                                item,
                                                                "end_date"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item["end_date"]
                                                                ? _vm.formatDate(
                                                                    item[
                                                                      "end_date"
                                                                    ]
                                                                  )
                                                                : ""
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : !item["end_date"] &&
                                                      !item["end_date_check"]
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mt-2 pl-0",
                                                          attrs: {
                                                            plain: "",
                                                            block: "",
                                                            "single-line": "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editItem(
                                                                item,
                                                                "end_date"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item["end_date"]
                                                                ? _vm.formatDate(
                                                                    item[
                                                                      "end_date"
                                                                    ]
                                                                  )
                                                                : ""
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : item["end_date_check"]
                                                    ? _c(
                                                        "v-menu",
                                                        {
                                                          ref: "dateMenu",
                                                          refInFor: true,
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "max-width":
                                                              "290px",
                                                            "min-width": "auto",
                                                            "nudge-left":
                                                              "100px",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.editField(
                                                                item,
                                                                "end_date"
                                                              )
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "persistent-hint":
                                                                                  "",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                autofocus:
                                                                                  "",
                                                                                "prepend-inner-icon":
                                                                                  "mdi-calendar",
                                                                                "hide-details":
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              blur: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.editField(
                                                                                  item,
                                                                                  "end_date"
                                                                                )
                                                                              },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  item[
                                                                                    "end_date"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item,
                                                                                      "end_date",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "item['end_date']",
                                                                              },
                                                                          },
                                                                          "v-text-field",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value: _vm.dateMenu,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.dateMenu = $$v
                                                            },
                                                            expression:
                                                              "dateMenu",
                                                          },
                                                        },
                                                        [
                                                          _c("v-date-picker", {
                                                            attrs: {
                                                              "no-title": "",
                                                              autofocus: "",
                                                              min: _vm.minEndDate,
                                                              position: "fixed",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                _vm.dateMenu = false
                                                              },
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.editField(
                                                                  item,
                                                                  "end_date"
                                                                )
                                                              },
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.editField(
                                                                  item,
                                                                  "end_date"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item[
                                                                  "end_date"
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "end_date",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item['end_date']",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item["end_date"]
                                                        ? _vm.formatDate(
                                                            item["end_date"]
                                                          )
                                                        : ""
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                          _c(
                                            "td",
                                            { attrs: { id: "taskedit" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    "aria-label": "Edit",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editTask(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v(" mdi-pencil-outline ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.$vuetify.breakpoint.mdAndUp
                          ? _c(
                              "tbody",
                              _vm._l(items, function (item) {
                                return _c(
                                  "tr",
                                  { key: item.task_id },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        attrs: {
                                          height: "auto",
                                          elevation: "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            attrs: {
                                              "no-gutters": "",
                                              justify: "space-between",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "ml-1 mt-2 mb-1 py-0",
                                                attrs: { cols: "10" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-subtitle-2 font-weight-bold",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "primary--text text-decoration-none",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editTask(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.task_name
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { "aria-label": "Edit" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editTask(item)
                                                  },
                                                },
                                              },
                                              [_vm._v(" mdi-pencil-outline ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "ml-1",
                                            class:
                                              !item["associated_ticket_ids"] ||
                                              item["associated_ticket_ids"]
                                                .length === 0
                                                ? "my-2"
                                                : "",
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular my-auto",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("ticket")) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "d-flex justify-end pa-0 pr-4",
                                              },
                                              [
                                                _c(
                                                  "section",
                                                  {
                                                    staticClass:
                                                      "avatars-group pa-3 stacked overflow-x-hidden py-0",
                                                  },
                                                  _vm._l(
                                                    item[
                                                      "associated_ticket_ids"
                                                    ],
                                                    function (avatar, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            "avatar-id-" +
                                                            avatar +
                                                            "-" +
                                                            index,
                                                          staticClass:
                                                            "avatars-group__item",
                                                        },
                                                        [
                                                          index < 1
                                                            ? _c("v-chip", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "grey--text text--darken-4 font-weight-regular body-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .associateTicketMap[
                                                                            avatar
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          index === 1
                                                            ? _c(
                                                                "v-avatar",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "grey lighten-2",
                                                                    size: "35",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "grey--text text--darken-4 font-weight-regular body-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " +" +
                                                                          _vm._s(
                                                                            item[
                                                                              "associated_ticket_ids"
                                                                            ]
                                                                              ? item[
                                                                                  "associated_ticket_ids"
                                                                                ]
                                                                                  .length -
                                                                                  1
                                                                              : 0
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "ml-1 my-2" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular my-auto",
                                                attrs: { cols: "4" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("assignedTo")) +
                                                    ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "d-flex justify-end text-right pa-0 pr-2 text-body-2 text--darken-2 font-weight-medium",
                                              },
                                              [
                                                !item["assignee_ids_check"]
                                                  ? _c(
                                                      "section",
                                                      {
                                                        staticClass:
                                                          "avatars-group pa-3 stacked overflow-x-hidden",
                                                      },
                                                      _vm._l(
                                                        item["assignee_ids"],
                                                        function (
                                                          avatar,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key:
                                                                "avatar-id-" +
                                                                avatar +
                                                                "-" +
                                                                index,
                                                              staticClass:
                                                                "avatars-group__item",
                                                            },
                                                            [
                                                              index < 6
                                                                ? _c(
                                                                    "v-avatar",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "blue lighten-4",
                                                                        size: "35",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "grey--text text--darken-4 font-weight-regular body-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .taskAssigneesMap[
                                                                                  avatar
                                                                                ]
                                                                                  ? _vm.GetInitials(
                                                                                      _vm
                                                                                        .taskAssigneesMap[
                                                                                        avatar
                                                                                      ]
                                                                                        .name
                                                                                    )
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              index === 6
                                                                ? _c(
                                                                    "v-avatar",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "blue lighten-4",
                                                                        size: "35",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "grey--text text--darken-4 font-weight-regular body-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " +" +
                                                                              _vm._s(
                                                                                item[
                                                                                  "assignee_ids"
                                                                                ]
                                                                                  ? item[
                                                                                      "assignee_ids"
                                                                                    ]
                                                                                      .length -
                                                                                      6
                                                                                  : 0
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "ml-1 mt-1 mb-2" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 my-auto text-body-2 text--darken-2 font-weight-regular",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("progress")) +
                                                    ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 text-body-2 text--darken-2 font-weight-medium",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass:
                                                    "mr-3 pa-0 fit ml-auto",
                                                  attrs: {
                                                    items: _vm.taskProgressList,
                                                    "menu-props": {
                                                      bottom: true,
                                                      offsetY: true,
                                                    },
                                                    outlined: "",
                                                    rounded: "",
                                                    dense: "",
                                                    flat: "",
                                                    "hide-details": "",
                                                    disabled:
                                                      item.progress === "100%",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.editField(
                                                        item,
                                                        "progress"
                                                      )
                                                    },
                                                    change: function ($event) {
                                                      return _vm.editField(
                                                        item,
                                                        "progress"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.progress,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "progress",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.progress",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "ml-1 my-2" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                attrs: { cols: "7" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("priority")) +
                                                    ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item["priority"])
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "ml-1 my-2" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                attrs: { cols: "7" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("startDate")) +
                                                    ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item["start_date"]
                                                        ? _vm.formatDate(
                                                            item["start_date"]
                                                          )
                                                        : ""
                                                    )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "ml-1 my-2" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                attrs: { cols: "7" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("latestFinishDate")
                                                  ) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item["end_date"]
                                                        ? _vm.formatDate(
                                                            item["end_date"]
                                                          )
                                                        : ""
                                                    )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "ml-1 my-2" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                                attrs: { cols: "4" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("description")
                                                  ) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "text-right pa-0 pr-4 text-body-2 text--darken-2 font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.description(item)
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
              _c(
                "div",
                { staticClass: "mr-3" },
                [
                  _vm.$vuetify.breakpoint.mdAndUp &&
                  _vm.checkPermission("createTask")
                    ? _c(
                        "v-col",
                        { staticClass: "ml-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { id: "add-task", elevation: "0" },
                              on: { click: _vm.addTaskClicked },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-plus-circle"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("addTask"))),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              this.taskTableData.length == 0
                ? _c(
                    "div",
                    { staticClass: "empty-task-state" },
                    [
                      _c(
                        "v-card",
                        {
                          class: _vm.$vuetify.breakpoint.smAndDown
                            ? "mb-12"
                            : null,
                          attrs: { elevation: "0", width: "100%" },
                        },
                        [
                          _c("v-row", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "h6 font-weight-medium grey--text text--darken-4 mx-auto my-2",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("noTasksAdded")) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c("v-row", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center",
                                staticStyle: { display: "block" },
                              },
                              [
                                _vm.$vuetify.breakpoint.mdAndUp
                                  ? [
                                      _vm._v(
                                        " Click the add task button to add task to this project "
                                      ),
                                    ]
                                  : [
                                      _vm._v(
                                        " Click the add task button to add task "
                                      ),
                                      _c("br"),
                                      _vm._v(" to this project "),
                                    ],
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndDown && _vm.checkPermission("createTask")
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "px-2 py-4",
              attrs: { fixed: "", horizontal: "", height: "70" },
              model: {
                value: _vm.bottomNavbar,
                callback: function ($$v) {
                  _vm.bottomNavbar = $$v
                },
                expression: "bottomNavbar",
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "px-4 py-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-none rounded-lg font-weight-regular text-h6 white--text",
                      attrs: { color: "primary", block: "", height: "50" },
                      on: { click: _vm.addTaskClicked },
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("addTask"))),
                      _c("v-icon", [_vm._v("mdi-plus")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        [
          _vm.$vuetify.breakpoint.mdAndUp && _vm.addTaskDialog
            ? _c(
                "div",
                { attrs: { id: "addTaskPanel" } },
                [
                  _c("AddTask", {
                    attrs: {
                      "edit-task-details": _vm.editTaskData,
                      "project-details": _vm.projectDetail,
                    },
                    on: {
                      taskAdded: _vm.taskAdded,
                      hideTaskDialog: _vm.hideTaskDialog,
                      taskDeleted: _vm.removeDeletedTask,
                      closeTaskDialog: _vm.closeTaskDialog,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.$vuetify.breakpoint.mdAndUp && _vm.addTaskDialog
            ? _c(
                "v-dialog",
                {
                  attrs: { fullscreen: "", width: "auto", height: "auto" },
                  model: {
                    value: _vm.addTaskDialog,
                    callback: function ($$v) {
                      _vm.addTaskDialog = $$v
                    },
                    expression: "addTaskDialog",
                  },
                },
                [
                  _c("AddTask", {
                    attrs: {
                      "project-details": _vm.projectDetail,
                      "edit-task-details": _vm.editTaskData,
                    },
                    on: {
                      taskAdded: _vm.taskAdded,
                      hideTaskDialog: _vm.hideTaskDialog,
                      taskDeleted: _vm.removeDeletedTask,
                      closeTaskDialog: _vm.closeTaskDialog,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }